var lockScreen = false;

if(typeof(gtmPageView) === "undefined") {
	var gtmPageView = function(page){
		if(typeof(dataLayer) !== 'undefined') {
			dataLayer.push({
				'event': 'gaEvent',
				'eventCategory': 'area',
				'eventAction': 'view',
				'eventLabel':  page
			});
			console.log('GTM: '+page);
		}
		console.log('V: '+page);
	};
}

var preloader = function($) {

	var img,
		loadingPercentage = 0,
		$preloader = $('<div></div>').css({width:0}),
        $images = $('body').find('img').not('body>img'),
        imagesCounter = 0,
		mobileImages = $(window).width()<920,
		images = [
			TEMPLATE_DIR+'/dist/images/wloclawek.png',
			TEMPLATE_DIR+'/dist/images/bg-main.jpg',
			TEMPLATE_DIR+'/dist/images/icon-events-move.png',
			TEMPLATE_DIR+'/dist/images/icon-events-scroll.png',
			TEMPLATE_DIR+'/dist/images/11.jpg',
			TEMPLATE_DIR+'/dist/images/12.jpg',
			TEMPLATE_DIR+'/dist/images/13.jpg',
			TEMPLATE_DIR+'/dist/images/14.jpg',
			TEMPLATE_DIR+'/dist/images/21.jpg',
			TEMPLATE_DIR+'/dist/images/22.jpg',
			TEMPLATE_DIR+'/dist/images/23.jpg',
			TEMPLATE_DIR+'/dist/images/24.jpg',
			TEMPLATE_DIR+'/dist/images/31.jpg',
			TEMPLATE_DIR+'/dist/images/32.jpg',
			TEMPLATE_DIR+'/dist/images/33.jpg',
			TEMPLATE_DIR+'/dist/images/34.jpg',
			TEMPLATE_DIR+'/dist/images/41.jpg',
			TEMPLATE_DIR+'/dist/images/42.jpg',
			TEMPLATE_DIR+'/dist/images/43.jpg',
			TEMPLATE_DIR+'/dist/images/44.jpg',
			TEMPLATE_DIR+'/dist/images/bg-front.jpg',
			TEMPLATE_DIR+'/dist/images/plus.png',
			TEMPLATE_DIR+'/dist/images/animations/kosmonauta.png',
			TEMPLATE_DIR+'/dist/images/animations/lajka.png',
			TEMPLATE_DIR+'/dist/images/animations/saturator.png',
			TEMPLATE_DIR+'/dist/images/animations/powstanie-wloclawka.png',
			TEMPLATE_DIR+'/dist/images/animations/fiat.png',
			TEMPLATE_DIR+'/dist/images/animations/budowa.png',
			TEMPLATE_DIR+'/dist/images/animations/mazowsze.png',
			TEMPLATE_DIR+'/dist/images/animations/zima.png',
			TEMPLATE_DIR+'/dist/images/animations/mur.png',
			TEMPLATE_DIR+'/dist/images/animations/pong.png',
			TEMPLATE_DIR+'/dist/images/animations/sklep.png',
			TEMPLATE_DIR+'/dist/images/animations/polonez.png',
			TEMPLATE_DIR+'/dist/images/animations/stadion.png',
			TEMPLATE_DIR+'/dist/images/animations/flagi-pl-onz.png',
			TEMPLATE_DIR+'/dist/images/animations/walesa.png',
			TEMPLATE_DIR+'/dist/images/animations/emotikony.png',
			TEMPLATE_DIR+'/dist/images/animations/dolly.png',
			TEMPLATE_DIR+'/dist/images/animations/flagi-pl-eu.png',
			TEMPLATE_DIR+'/dist/images/animations/mars.png'
		];
		
		$images.each(function(){
			var src = $(this).attr('src');
			if( src.indexOf('youtube.com')===-1) {
				images.push( src );
			}
		});
		
        $.each(images, function(index, url){
			img = new Image();
			
			img.onload = function(){
                imagesCounter++;

				loadingPercentage = Math.ceil(imagesCounter/images.length*100);

				$('.progressbar').css('height', 654*loadingPercentage/100);
				
				$('.preloader span').stop().animate({
					Counter: loadingPercentage
				}, {
					duration: 1200,
					easing: 'swing',
					step: function (now) {
						$(this).text(Math.ceil(now)+'%');
					}
				});
						
						
//						html(loadingPercentage+'%');
				if(loadingPercentage === 100) {
					$('.preloader').addClass('complete');
					$('.intro-text .btn').addClass('animated');
					
					window.setTimeout(function(){
						$('.draggable-container').addClass('visible-mouse-events');

						$('.help-box.active-contest').css({display: 'block'}).animate({opacity:1}, 600, function(){
							$(this).addClass('visible');
						});

						$('.help-box-overlay.active-contest').css({display: 'block'}).animate({opacity:0.3}, 600, function(){
							$(this).addClass('visible');
						});
					}, 2000);
				}
				
			};

			img.src = url;
			
			if ( img.complete || typeof(img.complete) === "undefined" ) {
				img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
				img.src = url;
			}

			img.error = function(){
		        imagesCounter++;
	            console.log('ERROR during loading an image');
			};

		});

        var preloaderInterval = null;

        preloaderInterval = setInterval(function(){
            if(images.length == imagesCounter) {

				window.setTimeout(function(){
					$('.mm-contest-intro-overlay').addClass('mm-visible');
				}, 2000);
				
				$('.preloader').delay(2000).fadeOut(1200, function(){
					
					$(this).remove();
					//lockScreen = false;
				});
                clearInterval(preloaderInterval);
				
            }
        },50);

};

jQuery(function($){
	"use strict";

	// Pan
	var $container = $( ".draggable-container" );
	var $animationContainer = $('.animation-container', $container);
	var $zoom = $('.zoom');
	var $move = $('.move');
	var fullWidth = $container.width();
	var fullHeight = $container.height();
	var imageWidth = 3966; //3840;
	var imageHeight = 2184; //2160;
	var availableZooms = [Math.max(fullWidth/imageWidth, fullHeight/imageHeight), 0.6, 0.75,1];
	var currentZoom = 0,
		currentArea = 'lata-60',
		openFrontContents = false;
	
	var temp = 1/availableZooms[currentZoom];
	$('.loading').css({transform: ' scale('+temp+')', 'transform-origin': '50% 100%'});

	window.setTimeout(function(){
		
		var img = new Image();
		img.src = TEMPLATE_DIR+'/dist/images/wloclawek.png';
		
		if ( img.complete || typeof(img.complete) === "undefined" ) {
			img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
			img.src = TEMPLATE_DIR+'/dist/images/wloclawek.png';
		}
		
		img.onload = function(){	
			preloader(jQuery);
		};
		
	}, 1000);
	

	var reqAnimationFrame = (function () {
	    return window[Hammer.prefixed(window, 'requestAnimationFrame')] || function (callback) {
	        window.setTimeout(callback, 1000 / 60);
	    };
	})();
	
	var START_X = 0; //Math.round((screen.offsetWidth - el.offsetWidth) / 2);
	var START_Y = 0; //Math.round((screen.offsetHeight - el.offsetHeight) / 2);

	var screen = document.querySelector(".device-screen");
	var el = $container[0];
	
	var dragBoundaries= {
		minx: imageWidth,
		maxx: 0,
		maxy: 0
	};


	$move.on('transitionend oTransitionEnd webkitTransitionEnd', function(){
		$(this).removeClass('autoanimation');
		lockScreen = false;
//		requestElementUpdate();
	});
	
	var item1 = $move[0];
	
	var zoomTimeout = 0;
	var firstTimeZoom = true;
	
	var translate = {
		x: START_X,
		y: START_Y
	};

	var changeDecade = function(newDecade) {
		if(currentArea !== newDecade) {
					$('.front-page-text>.text-'+currentArea).css({display: 'none'});
					currentArea = newDecade;
					$('.front-page-text>.text-'+currentArea).css({display: 'block'});
				}
	}
	
	var $frontPageContents = $('.front-page-contents');
	var checkPosition = function(){
		if(translate.y<0) {
		//	var visiblePixelsX = fullWidth/availableZooms[currentZoom]-fullWidth*0.4;
			
			if(translate.x<-400) {
				changeDecade('lata-90');
			} else {
				changeDecade('lata-80');
			}
		} else {
			if(translate.x<-500) {
				changeDecade('lata-70');
				
			} else if(translate.x>300) {
				changeDecade('lata-60');
				
			} else {
				changeDecade('xxi-w');
			}
		}
	};
	
	var updateZoom = function(hideContents) {
		
		var visibleFrontContents;
		var autoAnimation = false;
		
		if(hideContents === false || translate.x < -dragBoundaries.maxx) {
			$move.addClass('autoanimation');
			autoAnimation = true;
		}
		
		$zoom.css({transform: ' scale('+availableZooms[currentZoom]+')', 'transform-origin': '50% 50%'});
		
		if(currentZoom>0) {
			if(firstTimeZoom) {
				$('.intro-text').addClass('hidden-intro');

				window.setTimeout(function(){
					$('.intro-text').remove();
				},300);
				firstTimeZoom = false;
			}
			
		} else {
			var temp = 1/availableZooms[currentZoom];
			$('.intro-text').removeClass('hidden-intro').css({transform: ' scale('+temp+')', 'transform-origin': '50% 100%'});
			$('.front-page-contents').removeClass('visible-contents');
		}
		
		if(currentZoom >= availableZooms.length-2) {
			
//			if(hideContents === false){
//				$frontPageContents.addClass('open');
//				visibleFrontContents = true;
//			}
			if(hideContents === false) {
                            openFrontContents = true;
                            $frontPageContents.addClass('open');
                        }
                        
			$frontPageContents.addClass('visible-contents');
			$animationContainer.addClass('animated');
			$container.addClass('visible-zoom-icon');
                        
			
		} else {
			$animationContainer.removeClass('animated');
			$frontPageContents.removeClass('visible-contents').removeClass('open');
			$container.removeClass('visible-zoom-icon');
			openFrontContents = false;
		}
		
		dragBoundaries.maxx = (imageWidth/2 * availableZooms[currentZoom] - fullWidth/2);
		dragBoundaries.maxy = (imageHeight/2 * availableZooms[currentZoom] - fullHeight/2);
		dragBoundaries.minx = -dragBoundaries.maxx;
		dragBoundaries.miny = -dragBoundaries.maxy;
		
		
		dragBoundaries.minx -= (openFrontContents === true? 0.4*fullWidth : 0);
		
//		dragBoundaries.maxx -=100* availableZooms[currentZoom]
//		dragBoundaries.maxy -=50* availableZooms[currentZoom]
//		
//		dragBoundaries.minx -=100* availableZooms[currentZoom]
//		dragBoundaries.miny -=50* availableZooms[currentZoom]
		
		if(translate.x>dragBoundaries.maxx) {
			translate.x = dragBoundaries.maxx;
		} else if(translate.x<dragBoundaries.minx) {
			translate.x = dragBoundaries.minx;
		}

		if(translate.y>dragBoundaries.maxy) {
			translate.y = dragBoundaries.maxy;
		} else if(translate.y<dragBoundaries.miny) {
			translate.y = dragBoundaries.miny;
		}
		
		if(autoAnimation) {
			lockScreen = true;
		}
		
		START_X = translate.x;
		START_Y = translate.y;
				
		$move.css({
			transform: 'translate3d(' + translate.x + 'px, ' + translate.y + 'px, 0)'
		});
	};
	
	updateZoom(false);
	checkPosition();
	
	$(window).on('resize', function(){
		fullWidth = $container.width();
		fullHeight = $container.height();

		availableZooms = [Math.max(fullWidth/imageWidth, fullHeight/imageHeight), 0.6, 0.75,1];	
//		dragBoundaries.maxx = (imageWidth/2 * availableZooms[currentZoom] - fullWidth+fullWidth/2);
//		dragBoundaries.maxy = (imageHeight/2 * availableZooms[currentZoom] - fullHeight+fullHeight/2);
//		dragBoundaries.minx = -dragBoundaries.maxx;
//		dragBoundaries.miny = -dragBoundaries.maxy;
		updateZoom(false);
		checkPosition();
		$move.removeClass('autoanimation');
		lockScreen = false;
	});
	
	window.setTimeout( function() {
		$zoom.css({transition: 'all 600ms ease'});
	}, 400);

	var ticking = false;
	function updateElementTransform() {
		if(lockScreen) {
			return;
		}

		var value1 = 'translate3d(' + translate.x + 'px, ' + translate.y + 'px, 0)';

		item1.style.webkitTransform = value1;
		item1.style.mozTransform = value1;
		item1.style.transform = value1;

		ticking = false;
	}

	function requestElementUpdate() {
	    if(!ticking) {
	        reqAnimationFrame(updateElementTransform);
	        ticking = true;
	    }
	}
	
	
	var mc = new Hammer.Manager($container[0], {
		recognizers: [
			[Hammer.Pinch, { enable: true }],
		]
	});

	mc.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));

	mc.on("panstart", function() {
			if (document.selection) {
				document.selection.empty();
			} else if (window.getSelection) {
				window.getSelection().removeAllRanges();
			}
	});

	mc.on("panstart panmove", function (ev) {
	
		if(lockScreen) {
			return;
		}
		
	   translate = {
	        x: START_X + ev.deltaX,
	        y: START_Y + ev.deltaY
	    };

		if(translate.x>dragBoundaries.maxx) {
			translate.x = dragBoundaries.maxx;
		} else if(translate.x<dragBoundaries.minx) {
			translate.x = dragBoundaries.minx;
		}

		if(translate.y>dragBoundaries.maxy) {
			translate.y = dragBoundaries.maxy;
		} else if(translate.y<dragBoundaries.miny) {
			translate.y = dragBoundaries.miny;
		}

		requestElementUpdate();

	});
	
	mc.on("panend", function (ev) {
		START_X =  translate.x;
		START_Y =  translate.y;
		
		checkPosition();
	});
	
	
	mc.on("pinchout", function (event) {
		
		if($('body').hasClass('open-nav')) {
			return false;
		}
		event.preventDefault();
		 
		// scroll up
			if(!zoomTimeout) {
				zoomTimeout = window.setTimeout(function(){
					zoomTimeout = 0;
				}, 300);

				if(currentZoom+1<availableZooms.length){
					currentZoom++;

					//translate.x += (fullWidth/2 -event.pageX)*availableZooms[currentZoom];
					//translate.y += (fullHeight/2 -event.pageY)*availableZooms[currentZoom];
					
					updateZoom(false);
					checkPosition();
				}

			}
		
		return false;
	});
	
	mc.on("pinchin", function (event) {
		if($('body').hasClass('open-nav')) {
			return false;
		}
		event.preventDefault();
		 
			// scroll down
			if(!zoomTimeout) {
				zoomTimeout = window.setTimeout(function(){
					zoomTimeout = 0;
				}, 300);
				
				if(currentZoom>0) {
					currentZoom--;
					
					//translate.x += (fullWidth/2 -event.pageX)*availableZooms[currentZoom];
					//translate.y += (fullHeight/2 -event.pageY)*availableZooms[currentZoom];

					updateZoom(false);
					checkPosition();
				}
			}

		return false;
	});
	
	$('.controls-zoom-in').on('click', function(event){
		if($('body').hasClass('open-nav')) {
			return false;
		}
		event.preventDefault();
		 
			// scroll down
			if(!zoomTimeout) {
				zoomTimeout = window.setTimeout(function(){
					zoomTimeout = 0;
				}, 600);
				
				if(currentZoom+1<availableZooms.length){
					currentZoom++;

					updateZoom(false);
					checkPosition();
				}
			}

		return false;
	});
	
	$('.controls-zoom-out').on('click', function(event){
		if($('body').hasClass('open-nav')) {
			return false;
		}
		event.preventDefault();
		 
			// scroll down
			if(!zoomTimeout) {
				zoomTimeout = window.setTimeout(function(){
					zoomTimeout = 0;
				}, 600);
				
				if(currentZoom>0) {
					currentZoom--;

					updateZoom(false);
					checkPosition();
				}
			}

		return false;
	});
	
	$('.controls-move-up').on('click', function(){
		translate.y += (fullHeight/4)*availableZooms[currentZoom];
		updateZoom(false);
		checkPosition();
		return false;
	});

	$('.controls-move-down').on('click', function(){
		translate.y -= (fullHeight/4)*availableZooms[currentZoom];
		updateZoom(false);
		checkPosition();
		return false;
	});

	$('.controls-move-left').on('click', function(){
		translate.x += (fullWidth/4)*availableZooms[currentZoom];
		updateZoom(false);
		checkPosition();
		return false;
	});

	$('.controls-move-right').on('click', function(){
		translate.x -= (fullWidth/4)*availableZooms[currentZoom];
		updateZoom(false);
		checkPosition();
		return false;
	});

	  $(window).bind('mousewheel DOMMouseScroll', function(event){

		if(lockScreen) {
			return;
		}
		
		if($('body').hasClass('open-nav')) {
			return false;
		}
		
		lockScreen = true;
		
		event.preventDefault();
		 
		if (event.originalEvent.wheelDelta >0 || event.originalEvent.detail<0) {
			// scroll up
//			if(!zoomTimeout) {
//				zoomTimeout = window.setTimeout(function(){
//					zoomTimeout = 0;
//				}, 300);

				if(currentZoom+1<availableZooms.length){
					currentZoom++;

					translate.x += (fullWidth/2 -event.pageX)*availableZooms[currentZoom];
					translate.y += (fullHeight/2 -event.pageY)*availableZooms[currentZoom];
					
					updateZoom(false);
					checkPosition();
				} else {
					lockScreen = false;
				}

//			}
		}
		else {
			// scroll down
//			if(!zoomTimeout) {
//				zoomTimeout = window.setTimeout(function(){
//					zoomTimeout = 0;
//				}, 300);
				
				if(currentZoom>0) {
					currentZoom--;
					
					translate.x += (fullWidth/2 -event.pageX)*availableZooms[currentZoom];
					translate.y += (fullHeight/2 -event.pageY)*availableZooms[currentZoom];

					updateZoom(false);
					checkPosition();
				} else {
					lockScreen = false;
				}
//			}
		}
		return false;
	});
  
	$('.open-help-box').on('click', function(){
		$('.help-box').css({display: 'block'}).animate({opacity:1}, 600, function(){
			$(this).addClass('visible');
		});
		
		$('.help-box-overlay ').css({display: 'block'}).animate({opacity:0.3}, 600, function(){
			$(this).addClass('visible');
		});
	});
	
	$('.close-help-box').on('click', function(){
		$('.help-box, .help-box-overlay ').animate({opacity:0}, 600, function(){
			$(this).removeClass('visible').css({display: 'none'});
		});
	});
        
        $('.help-box-overlay').on('click', function() {
            $('.help-box, .help-box-overlay ').animate({opacity:0}, 600, function(){
                $(this).removeClass('visible').css({display: 'none'});
            });
            return false;
        });

	$('.goto-60').on('click', function(){
		currentZoom = availableZooms.length-2;
		$('.front-page-text>.text-'+currentArea).css({display: 'none'});
		currentArea = 'lata-60';
		gtmPageView(currentArea);
		
		if(fullWidth>1050) {
			translate.x = (imageWidth * availableZooms[currentZoom] - fullWidth+fullWidth)/11*2;
			translate.y = (imageHeight * availableZooms[currentZoom] - fullHeight+fullHeight)/2;
		} else { // iPad
			translate.x = (imageWidth * availableZooms[currentZoom] - fullWidth+fullWidth)/12*2;
			translate.y = (imageHeight * availableZooms[currentZoom] - fullHeight+fullHeight)/12*2;
		}

		$('.front-page-contents').addClass('visible-contents');
		$('.front-page-text>div').css({display: 'none'});
		$('.front-page-text>.text-'+currentArea).css({display: 'block'});

		$('.draggable-container').addClass('visible-mouse-events');

		$('.help-box').css({display: 'block'}).animate({opacity:1}, 600, function(){
			$(this).addClass('visible');
		});
		
		$('.help-box-overlay ').css({display: 'block'}).animate({opacity:0.3}, 600, function(){
			$(this).addClass('visible');
		});

//		openFrontContents = true;
//		$('.front-page-contents').addClass('open');
				
		updateZoom(false);
		
		//return false;
	});

	$('.goto').on('click', function(){

		$('.front-page-text>.text-'+currentArea).css({display: 'none'});

		currentArea = $(this).data('goto');
		gtmPageView(currentArea);
		
		if(currentArea === 'lata-60') {
//			currentZoom = 0;
//			updateZoom(false);
			
		}

//			window.setTimeout(function () {
				$('.front-page-text>.text-'+currentArea).css({display: 'block'});

				$('.draggable-container').addClass('visible-mouse-events');
				switch(currentArea) {
					case 'lata-60':
						currentZoom = availableZooms.length-2;
						$('.front-page-text>.text-'+currentArea).css({display: 'block'});
						if(fullWidth>1050) {
							translate.x = (imageWidth * availableZooms[currentZoom] - fullWidth+fullWidth)/11*2;
							translate.y = (imageHeight * availableZooms[currentZoom] - fullHeight+fullHeight)/2;
						} else { // iPad
							translate.x = (imageWidth * availableZooms[currentZoom] - fullWidth+fullWidth)/12*2;
							translate.y = (imageHeight * availableZooms[currentZoom] - fullHeight+fullHeight)/12*2;
						}
						break;
					case 'lata-70':
						translate.x = -(imageWidth * availableZooms[currentZoom])/12*8;
						translate.y = (imageHeight * availableZooms[currentZoom])/2;
						break;
					case 'lata-80':
						translate.x = (imageWidth * availableZooms[currentZoom])/12*2;
						translate.y = -(imageHeight * availableZooms[currentZoom])/2;
						break;
					case 'lata-90':
						translate.x = -(imageWidth * availableZooms[currentZoom])/12*8;
						translate.y = -(imageHeight * availableZooms[currentZoom])/2;
						break;
					case 'xxi-w':
						translate.x = -(imageWidth * availableZooms[currentZoom])/12;
						translate.y = (imageHeight * availableZooms[currentZoom])/2;
						break;
					case 'start':
						translate.x = 0;
						translate.y = 0;
						currentZoom = 0;
				}

				openFrontContents = true;
				$('.front-page-contents').addClass('open');
		
				updateZoom(false);
//			}, 900);
		
		
		//return false;
	});

	$('.plus').hover(function(){
		
		$('.front-page-text>div').css({display: 'none'});
		$('.front-page-text>.text-'+$(this).data('item')).css({display: 'block'});
		
		openFrontContents = true;
		$('.front-page-contents').addClass('open');
		dragBoundaries.minx = -(dragBoundaries.maxx + 0.4*fullWidth);

		gtmPageView($(this).data('item'));
			
		return false;
	}, function(){
		$('.front-page-text>.text-'+$(this).data('item')).css({display: 'none'});

		$('.front-page-text>.text-'+currentArea).css({display: 'block'});
	}).on('click', function(){
		gtmPageView($(this).data('item'));
	});
	

	
	$('.goto-center').on('click', function(){
		currentZoom = 0;
		
		translate.x = 0; //(imageWidth/2 * availableZooms[currentZoom] - fullWidth+fullWidth/2);
		translate.y = 0; //(imageHeight/2 * availableZooms[currentZoom] - fullHeight+fullHeight/2);
		
		updateZoom(false);
		
		$('.front-page-contents').removeClass('visible-contents');
		
		return false;
	});
	
	
	$('.close-front-page-contents').on('click', function(){
		openFrontContents = false;
		$('.front-page-contents').removeClass('open');
		updateZoom(true);
		return false;
	});
	
	$('.open-front-page-contents').on('click', function(){
		openFrontContents = true;
		$('.front-page-contents').addClass('open');
		updateZoom(true);
		return false;
	});
	
});

